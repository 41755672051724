'use client';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Link } from '@mentimeter/ragnar-ui/link';
import { Box } from '@mentimeter/ragnar-ui/box';

export function NoScript(): React.ReactElement<'noscript'> {
  return (
    <noscript style={{ width: '100%' }}>
      <Box zIndex={11}>
        <Box
          width="100%"
          justifyContent="center"
          alignItems="center"
          p={3}
          bg="negative"
        >
          <Text
            fontWeight="semiBold"
            lineHeight="heading"
            as="h1"
            textAlign="center"
            color="onNegative"
          >
            Please turn on Javascript
          </Text>
          <Text textAlign="center" my={1} color="onNegative">
            Your browser either doesn&apos;t support Javascript, or you&apos;ve
            turned it off.
          </Text>
          <Text textAlign="center" color="onNegative">
            To use Mentimeter, please make sure to turn Javascript on.&nbsp;
          </Text>
          <Link
            color="onNegative"
            textAlign="center"
            href="https://www.enable-javascript.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            How do I enable Javascript?
          </Link>
        </Box>
      </Box>
    </noscript>
  );
}
