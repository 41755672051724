export const invoiceRequestBundle = {
  'en-US': { invoiceRequest: require('./en-US/invoiceRequest.json') },
  'de-DE': { invoiceRequest: require('./de-DE/invoiceRequest.json') },
  'pt-BR': { invoiceRequest: require('./pt-BR/invoiceRequest.json') },
  'es-ES': { invoiceRequest: require('./es-ES/invoiceRequest.json') },
};

export const manageRolesBundle = {
  'en-US': { manageRoles: require('./en-US/manageRoles.json') },
  'de-DE': { manageRoles: require('./de-DE/manageRoles.json') },
  'pt-BR': { manageRoles: require('./pt-BR/manageRoles.json') },
  'es-ES': { manageRoles: require('./es-ES/manageRoles.json') },
};
